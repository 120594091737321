<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>

<style lang="less">
.nav-fixed {
  width: 100vw;
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
}
html {
  overflow-x: hidden;
}
</style>
