import Vue from "vue";
import App from "./App.vue";

import Vant from "vant";
import "vant/lib/index.css";
import "./assets/fonts/iconfont.css";
import "./styles";

import Router from "vue-router";
import localRoutes from "./router";

Vue.use(Vant);
Vue.use(Router);
let router = new Router({});
router.options.routes = localRoutes;
router.addRoutes(localRoutes);
// 路由全局守卫，切换路由回到顶点
router.beforeEach((to, from, next) => {
  localStorage.setItem("routes", from.path);
  next();
});
router.afterEach(() => {
  window.scrollTo(0, 0);
});
window.document.title = "电子学员手册";

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
