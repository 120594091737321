export default [
  {
    path: "/home",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "./views/home.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "./views/login.vue"),
  },
  {
    path: "/detail",
    name: "detail",
    component: () =>
      import(/* webpackChunkName: "detail" */ "./views/detail.vue"),
  },
  {
    path: "/student",
    name: "student",
    component: () =>
      import(/* webpackChunkName: "student" */ "./views/student.vue"),
  },
  {
    path: "/plan",
    name: "plan",
    component: () => import(/* webpackChunkName: "plan" */ "./views/plan.vue"),
  },
  {
    path: "/teacher",
    name: "teacher",
    component: () =>
      import(/* webpackChunkName: "teacher" */ "./views/teacher.vue"),
  },
  {
    path: "/viewer",
    name: "viewer",
    component: () =>
      import(/* webpackChunkName: "viewer" */ "./views/viewer.vue"),
  },
];
