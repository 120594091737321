const modules = require.context("@/views/", true, /index\.js$/);

let router = [];
for (const key of modules.keys()) {
  router = router.concat(modules(key).default);
}

export default [
  {
    path: "*",
  },
  {
    path: "/",
    redirect: "/login",
  },
  ...router,
];
